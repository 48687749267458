import * as React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Layout from "../components/layout"
import { colors } from "../common/themeStyle"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from "../components/seo"
import { useLocation } from "@reach/router"
import YouTube from "react-youtube"

const About = () => {
  const windowLocation = useLocation()
  const videoId = "J31c7pPHdgU"

  return (
    <Layout>
      <SEO pageTitle="ABOUT" pagePath={windowLocation.pathname} />
      <AboutWrapper>
        <StyledUpBox>
          <StaticImage
            src="../images/kumachon_about_1.png"
            alt="kumachon_about_1"
          />
        </StyledUpBox>

        <StyledMiddleBox>
          <StyledNetabareHeadSentence>
            バーチャルを通し<LessThanMediumBreake/>リアルの距離をなくす<LessThanMediumBreake/>
            <GreaterThanMediumBreak />
            くまちょんを世界へ
          </StyledNetabareHeadSentence>
          <StyledNetabareSentence>
            あらゆるモノが身近になった21世紀、
            <GreaterThanMediumBreak />
            ついにモノとモノ、バーチャルとリアルの距離をなくすべく、
            <GreaterThanMediumBreak />
            くまちょんが世界を目指します。
          </StyledNetabareSentence>
          <StyledSectionTitle>所属タレント</StyledSectionTitle>
          <AboutContents>
            <StyledUpBox>
              <StaticImage
                src="../images/kumachon_about_2.png"
                alt="kumachon_about_2"
              />
            </StyledUpBox>
            <StyledNetabareSentenceName>
              VTuber くまちょん
            </StyledNetabareSentenceName>
            <StyledNetabareSentence>
              ECサイトの上手な構築を武器に、バーチャルを利用したリアルの距離をなくすべく、
              <GreaterThanMediumBreak />
              日々楽しくECサイトの学習を行い、ECサイトのTipsを広めることを使命としている。
            </StyledNetabareSentence>
            <YouTubeWrapper>
              <YouTube
                videoId={videoId}
                className="kumachon-introduction-video"
              />
            </YouTubeWrapper>
          </AboutContents>
          <StyledSectionTitle>会社概要</StyledSectionTitle>
          <AboutContents>
            <Table>
              <tbody>
                <tr>
                  <th>会社名</th>
                  <td>株式会社くまちょん</td>
                </tr>
                <tr>
                  <th>設立</th>
                  <td>くま帝国暦 2800年 4月1日</td>
                </tr>
                <tr>
                  <th>資本</th>
                  <td>
                    鮭　1,000本 <br /> ラーメン　500,000杯
                  </td>
                </tr>
                <tr>
                  <th>代表者</th>
                  <td>くまちょん</td>
                </tr>
                <tr>
                  <th>事業内容</th>
                  <td>
                    VTuberプロダクション事業
                    <br /> メディアミックス事業
                  </td>
                </tr>
                <tr>
                  <th>オフィス</th>
                  <td>
                    東京都
                    <br />
                    (とある区にあるちょっと下町情緒があふれるとあるビルの階段を31段登った所)
                  </td>
                </tr>
              </tbody>
            </Table>
          </AboutContents>
        </StyledMiddleBox>
      </AboutWrapper>
    </Layout>
  )
}

export default About

const YouTubeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  margin-bottom: 50px;
  .kumachon-introduction-video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`
const AboutWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 0;
`
const AboutContents = styled.div`
  margin-bottom: 80px;
`
const Table = styled.table`
  max-width: 850px;
  font-size: 16px;
  margin: auto auto 64px;
  ${media.lessThan("medium")`
    font-size: 12px;
  `}
`

const StyledUpBox = styled.div`
  margin: 0 auto;
  width: 500px;
  margin-bottom: 30px;
  margin-top: 60px;
  ${media.lessThan("medium")`
  width: 300px;
  margin: 0 auto;
  `}
`

const StyledMiddleBox = styled.div`
  margin-bottom: 10px;
`

const StyledNetabareHeadSentence = styled.h1`
  line-height: 1.3;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  margin-bottom: 30px;
  ${media.lessThan("medium")`
    font-size: 30px;
  `}
`

const StyledNetabareSentenceName = styled.p`
  height: 42px;
  left: 428px;
  top: 778px;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 20px;
  color: ${colors.black};
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`

const StyledNetabareSentence = styled.p`
  height: 42px;
  left: 428px;
  top: 778px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 110px;
  color: ${colors.black};
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`

const StyledSectionTitle = styled.h2`
  letter-spacing: 0.07em;
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  color: rgb(51, 51, 51);
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  &::before {
    border-top: 1px solid ${colors.yellow};
    content: "";
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-right: 80px;
  }
  &::after {
    border-top: 1px solid ${colors.yellow};
    content: "";
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-left: 80px;
  }
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`

const GreaterThanMediumBreak = styled.br`
  ${media.lessThan("medium")`
    display: none;
  `}
`
const LessThanMediumBreake = styled.br`
  ${media.greaterThan("medium")`
    display: none;
  `}
`
